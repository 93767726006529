import {
  CreateControllerFn,
  ControllerParams,
} from 'yoshi-flow-editor-runtime';
import { appName } from '../../../.application.json';
import { getGogDataAndCovers } from '../../services/gogHelper';
import { getSiteTranslations } from '../../config/i18n';

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}: ControllerParams) => {
  const { appParams, setProps, config, compId } = controllerConfig;
  const {
    fedopsLogger,
    getSiteLanguage,
    widgetId,
    inEditor,
    environment,
    getExperiments,
  } = flowAPI;

  const language = getSiteLanguage();
  const translations = await getSiteTranslations(language);
  const experimentManager = await getExperiments();
  const isExperimentEnabled = (key: string) => experimentManager.enabled(key);
  const { baseUrls = {}, appDefinitionId, instance, instanceId } = appParams;
  let albumsCovers: GalleryItem[] = [];
  let coverIdToAlbum: { [id: string]: GogAlbum } = {};
  let gogData: GogData | { albums: GogAlbum[] } = { albums: [] };
  const isNewDefaultAlbums = true;//isExperimentEnabled('specs.albums.gogNewDefaultAlbums');

  const defaultAlbumsIds =
    config &&
    config.publicData &&
    config.publicData.APP &&
    config.publicData.APP.defaultAlbums &&
    config.publicData.APP.defaultAlbums.ids;

  try {
    const result = await getGogDataAndCovers(
      instanceId,
      instance,
      compId,
      defaultAlbumsIds,
      isNewDefaultAlbums,
    );
    gogData = result.gogData;
    albumsCovers = result.albumsCovers;
    coverIdToAlbum = result.coverIdToAlbum;
  } catch (e) {
    console.error('Failed to fetch GoG data - ' + e.message);
  }

  const setHeightFunc = (height: number) => setProps({ dimensions: height });
  return {
    async pageReady() {
      setProps({
        appName,
        cssBaseUrl: baseUrls.staticsBaseUrl,
        mobile: environment.isMobile,
        language,
        translations,
        instance,
        instanceId,
        setHeight: setHeightFunc,
        albumsCovers,
        gogData,
        coverIdToAlbum,
        inEditor,
        isExperimentEnabled,
        fetchAlbums: async () => {
          return getGogDataAndCovers(
            instanceId,
            instance,
            compId,
            defaultAlbumsIds,
            isNewDefaultAlbums,
          );
        },
      });
    },
  };
};

export default createController;
